import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import Error from 'components/actionModal/Error';
import CheckBoxInput from 'components/actionModal/forms/inputs/CheckBoxInput';
import UploadOfferPageField from 'components/actionModal/forms/uploadOfferPageField/UploadOfferPageField';
import DefaultButton from 'components/buttons/DefaultButton';
import { AffiliateOfferContext } from 'modules/offers/context/AffiliateOfferContext';
import useReloadImage from 'modules/offers/hooks/useReloadImage';
import useSetFileHandler from 'modules/offers/hooks/useSetFileHandler';
import {
  AffiliateSettingsPageAttributes,
  ConversionFunnelBE,
  DROP_IMAGE_EXTENSIONS,
  IMAGE_EXTENSIONS,
} from 'modules/offers/models';

import {
  FormContainer,
  ModalStage,
  MultipleInputsContainer,
  SubmitButtonContainer,
} from '../../styledElements/formsStyledElements';
import { Title } from '../../styledElements/stagesStyledElements';
import PreviewBlock, { PreviewOffer } from '../offerPage/PreviewBlock';

export const CONVERSION_FUNNEL_OPTIONS = [
  {
    id: 1,
    value: 'App code',
    conversionFunnel: ConversionFunnelBE.App,
  },
  {
    id: 2,
    value: 'Stripe checkout',
    conversionFunnel: ConversionFunnelBE.Web,
  },
];

type AffiliateOfferSettingsProps = {
  isShowStage: boolean;
  settings: AffiliateSettingsPageAttributes;
  isEdit?: boolean;
  isUpdatedImage?: boolean;
  isPending?: boolean;
  title: string;
  imageName: string;
  id?: string;
  image: string;
  previewOffer: PreviewOffer;
  submitButtonTitle: string;
  saveSettings: (settings: AffiliateSettingsPageAttributes) => void;
  goToNextStage: (data: AffiliateSettingsPageAttributes) => void;
  saveImageName: (name: string) => void;
  saveImage: (
    urlImg: string,
    toggleFormView: () => void,
    openError: (value: boolean) => void,
    setErrorText: (value: string) => void,
    textWithPercentages?: HTMLParagraphElement | null,
    file?: File
  ) => void;
};

const AffiliateOfferSettings = ({
  isShowStage,
  isEdit,
  settings,
  isUpdatedImage = true,
  isPending,
  title,
  image,
  imageName,
  previewOffer,
  submitButtonTitle,
  saveSettings,
  saveImage,
  goToNextStage,
  saveImageName,
}: AffiliateOfferSettingsProps) => {
  const { imageFile, offerDraft } = useContext(AffiliateOfferContext);

  const [isFormHidden, setIsFormHidden] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [logoError, setLogoError] = useState(false);

  const toggleFormView = useCallback(() => {
    setIsFormHidden(!isFormHidden);
  }, [isFormHidden]);

  const saveConversionFunnelValue = useCallback(
    (id: number) => {
      const conversionFunnel = CONVERSION_FUNNEL_OPTIONS.find(
        (data) => data.id === id
      )?.conversionFunnel;

      if (conversionFunnel) {
        saveSettings({
          ...settings,
          conversionFunnel,
        });
      }
    },
    [saveSettings, settings]
  );

  const setFileHandler = useSetFileHandler({
    toggleFormView,
    setLogoError,
    saveImage,
    setErrorText,
  });

  useReloadImage({
    imageFile,
    image,
    saveImage,
    setLogoError,
    setErrorText,
    isEdit,
    isShowStage,
    offerId: offerDraft.affiliateOfferId,
  });

  const selectedConversionFunnel = useMemo(
    () =>
      CONVERSION_FUNNEL_OPTIONS.find(
        ({ conversionFunnel }) => conversionFunnel === settings.conversionFunnel
      )?.id || CONVERSION_FUNNEL_OPTIONS[0].id,
    [settings.conversionFunnel]
  );

  const formSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { backgroundColour, conversionFunnel } = settings;

    goToNextStage({
      backgroundColour,
      conversionFunnel,
    });
  };

  return (
    <ModalStage $isShowStage={isShowStage}>
      <Title>{title}</Title>
      {!isFormHidden && (
        <FormContainer onSubmit={formSubmit}>
          {!isEdit && (
            <MultipleInputsContainer>
              <CheckBoxInput
                label="Conversion funnel"
                options={CONVERSION_FUNNEL_OPTIONS}
                selectedId={selectedConversionFunnel}
                onSelectedChange={saveConversionFunnelValue}
              />
            </MultipleInputsContainer>
          )}
          {!isFormHidden && (
            <SubmitButtonContainer>
              <DefaultButton
                text={submitButtonTitle}
                textColor={theme.colors.white}
                isActive={!isPending && isUpdatedImage}
                isSubmit
                withShadow
                buttonColor={
                  !isPending ? theme.colors.swamp : theme.colors.quillGray
                }
                isUppercase
                buttonSize="md"
                borderSize="md"
              />
            </SubmitButtonContainer>
          )}
        </FormContainer>
      )}

      <UploadContainer>
        <UploadOfferPageField
          isShowStage={isShowStage}
          isPromotionUpload
          pictureDescription="Square, transparent background, Max file size 5MB"
          fileDropZoneText="Drag & drop the replacement affiliate image here, or"
          fileName={imageName}
          file={image}
          fileError={logoError}
          validExtensions={IMAGE_EXTENSIONS}
          validExtensionsForDrop={DROP_IMAGE_EXTENSIONS}
          uploadSuggestionText="Add affiliate image"
          uploadSuggestionFormat="(.SVG, .JPEG, .JPG, .PNG)"
          setFileError={setLogoError}
          toggleForm={toggleFormView}
          setErrorText={setErrorText}
          setFileName={saveImageName}
          setFile={setFileHandler}
        />
      </UploadContainer>
      <PreviewBlock
        isShowStage={isShowStage}
        previewOffer={previewOffer}
        logo={image}
      />
      <Error isOpen={logoError} errorText={errorText} />
    </ModalStage>
  );
};

const UploadContainer = styled.div`
  .upload-suggestion {
    margin-top: 24px;
  }
`;

export default React.memo(AffiliateOfferSettings);
