import React, { useCallback, useEffect, useRef, useState } from 'react';
import Sticky from 'react-sticky-el';
import theme from 'theme';

import Header from 'components/detailsModal/Header';
import ModalAction from 'components/detailsModal/ModalAction';
import ModalNavigation from 'components/detailsModal/ModalNavigation';
import {
  CLOSE_DETAILS_MODAL_TIMER,
  RequestStatus,
} from 'components/detailsModal/models';
import {
  DetailsModalContainer,
  DetailsModalContent,
} from 'components/detailsModal/styledElements/detailsModalStyledElements';
import SuccessStage from 'components/detailsModal/SuccessStage';
import { utcToCustomV2 } from 'helpers/utcFormatToCustom';
import useOutsideClick from 'hooks/useOutsideClick';
import usePrevious from 'hooks/usePrevious';

import useChangeUserStatus from '../hooks/useChangeUserStatus';
import { User, UserStatusBE, userStatusesFE, UserStatusFE } from '../models';
import {
  deleteUserRequest,
  disableUserRequest,
} from '../usersServices/usersAccountsService';

import UserDetailsInformation from './UserDetailsInformation';

type Props = {
  isDataLoading: boolean;
  user: User;
  isOpen: boolean;
  closeWindow: () => void;
  openEditingModalWindow: () => void;
};

const UsersDetailsModal = ({
  isDataLoading,
  user,
  isOpen,
  closeWindow,
  openEditingModalWindow,
}: Props) => {
  const [requestStatus, setRequestStatus] = useState<RequestStatus | null>(
    null
  );
  const [isActionConfirmed, setIsActionConfirmed] = useState(false);
  const [activeStatus, setActiveStatus] = useState(UserStatusFE.Active);
  const [backgroundColor, setBackgroundColor] = useState('');
  const previousActiveStatus = usePrevious(activeStatus);
  const [successTitle, setSuccessTitle] = useState('');
  const [successText, setSuccessText] = useState('');
  const modalRef = useRef<HTMLDivElement>(null);

  const deleteUser = useChangeUserStatus(
    setSuccessTitle,
    setRequestStatus,
    deleteUserRequest
  );

  const disableUser = useChangeUserStatus(
    setSuccessTitle,
    setRequestStatus,
    disableUserRequest
  );

  const isSuccessStageOpen =
    disableUser.isLoading ||
    deleteUser.isLoading ||
    isDataLoading ||
    requestStatus;

  const userAge = new Date().getFullYear() - user.birthYear;

  const onDelete = useCallback(() => {
    setSuccessTitle('deleted');
    setSuccessText('The user will no longer be able to recover their account');
    deleteUser.mutate({
      userAccountId: user.id,
    });
  }, [deleteUser, user.id]);

  const onDisable = useCallback(() => {
    setSuccessTitle('disabled');
    setSuccessText(
      'The user won’t be able to sign into Shido until reactivated'
    );
    disableUser.mutate({
      userAccountId: user.id,
    });
  }, [disableUser, user.id]);

  const changeActiveStatus = useCallback(
    (index: number) => setActiveStatus(userStatusesFE[index]),
    []
  );

  const toggleDisableStatus = useCallback(() => {
    setIsActionConfirmed(!isActionConfirmed);
    setBackgroundColor(!isActionConfirmed ? theme.colors.secondary : '');
  }, [isActionConfirmed]);

  const toggleDeleteStatus = useCallback(() => {
    setIsActionConfirmed(!isActionConfirmed);

    if (!isActionConfirmed) {
      setBackgroundColor(theme.colors.hibiscus);
    } else if (user.status === UserStatusBE.Disabled) {
      setBackgroundColor(theme.colors.secondary);
    } else {
      setBackgroundColor('');
    }
  }, [isActionConfirmed, user.status]);

  useOutsideClick(modalRef, closeWindow);

  useEffect(() => {
    if (previousActiveStatus !== activeStatus) {
      setIsActionConfirmed(false);

      if (user.status !== UserStatusBE.Disabled) {
        setBackgroundColor('');
      } else if (user.status === UserStatusBE.Disabled) {
        setBackgroundColor(theme.colors.secondary);
      }
    }
  }, [activeStatus, previousActiveStatus, user.status]);

  useEffect(() => {
    if (requestStatus) {
      setTimeout(() => {
        closeWindow();
      }, CLOSE_DETAILS_MODAL_TIMER);
    }
  }, [requestStatus, successTitle, closeWindow]);

  useEffect(() => {
    const { status } = user;

    if (status === UserStatusBE.Active) {
      setActiveStatus(UserStatusFE.Active);
    } else if (status === UserStatusBE.Disabled) {
      setActiveStatus(UserStatusFE.Disable);
      setBackgroundColor(theme.colors.secondary);
    }
  }, [user]);

  return (
    <DetailsModalContainer ref={modalRef} $shouldRender={isOpen}>
      <Sticky
        topOffset={-80}
        stickyStyle={{
          top: 85,
        }}
      >
        <DetailsModalContent
          $backgroundColor={isDataLoading ? '' : backgroundColor}
        >
          {isSuccessStageOpen ? (
            <SuccessStage
              requestStatus={requestStatus}
              name={user.email}
              text={successText}
              title={successTitle}
            />
          ) : (
            <>
              <Header
                name={user.email}
                closeModal={closeWindow}
                openEditingModalWindow={openEditingModalWindow}
              />
              <ModalNavigation
                parameters={userStatusesFE}
                activeStatus={activeStatus}
                setActiveStatus={changeActiveStatus}
              />
              {activeStatus === UserStatusFE.Active && (
                <UserDetailsInformation
                  joined={utcToCustomV2(user.createdDateTime)}
                  location={user.timezoneId}
                  name={user.userName}
                  email={user.email}
                  gender={user.gender}
                  age={userAge}
                />
              )}
              {activeStatus === UserStatusFE.Disable &&
                user.status !== UserStatusBE.Disabled && (
                  <ModalAction
                    buttonText="disable"
                    isChecked={isActionConfirmed}
                    color={backgroundColor}
                    submitAction={onDisable}
                    closeModal={closeWindow}
                    toggleStatus={toggleDisableStatus}
                    actionTypeText="YES DISABLE THIS USER ACCOUNT"
                    note="The user won’t be able to sign in to Shido"
                  />
                )}
              {activeStatus === UserStatusFE.Delete && (
                <ModalAction
                  buttonText="delete"
                  isChecked={isActionConfirmed}
                  color={backgroundColor}
                  submitAction={onDelete}
                  closeModal={closeWindow}
                  toggleStatus={toggleDeleteStatus}
                  actionTypeText="YES DELETE THIS USER ACCOUNT"
                  note="The user will not be able to recover their account"
                />
              )}
            </>
          )}
        </DetailsModalContent>
      </Sticky>
    </DetailsModalContainer>
  );
};

export default UsersDetailsModal;
