import React, { useCallback, useMemo, useState } from 'react';

import { PAGE_SIZES, TableRowGrid } from 'components/table/models';
import Pagination from 'components/table/Pagination';
import { TableContainer } from 'components/table/styledElements/tableStyledElements';
import TableHeader from 'components/table/TableHeader';
import TableInfo from 'components/table/TableInfo';
import calculateMaxPagesNumber from 'helpers/calculateMaxPagesNumber';
import { cleanAccountLocalStorage } from 'helpers/connectToLocalStorage';

import { EditableDelegateAttributes, USERS_SORT_BY_FE } from '../models';

import DelegateContainerRow from './DelegateContainerRow';
import EditAccessAccountModal from './EditDelegatesModal';

type SubscribedTableProps = {
  data?: EditableDelegateAttributes[];
  pageSize: number;
  dataAmount: number;
  sortByListItemIndex: number;
  sortUsers: (index: number) => void;
  changePageSize: (pageSize: number) => void;
};

const SubscribedTable = ({
  data,
  pageSize,
  changePageSize,
  sortByListItemIndex,
  sortUsers,
  dataAmount,
}: SubscribedTableProps) => {
  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);
  const [dataForModal, setDataForModal] =
    useState<EditableDelegateAttributes>();

  const [page, setPage] = useState(1);
  const maxPages = useMemo(
    () => calculateMaxPagesNumber(dataAmount, pageSize),
    [dataAmount, pageSize]
  );

  const toggleEditingModalWindow = useCallback(() => {
    setIsEditingModalOpen(!isEditingModalOpen);
    cleanAccountLocalStorage();
  }, [isEditingModalOpen]);

  const switchFromDetailsToEditModal = useCallback(
    (data: EditableDelegateAttributes) => {
      toggleEditingModalWindow();
      setDataForModal(data);
    },
    [toggleEditingModalWindow]
  );

  return (
    <>
      <TableInfo
        employersPage
        pageSizes={PAGE_SIZES}
        dataAmount={dataAmount}
        activePageSize={pageSize}
        changePageSize={changePageSize}
      />
      <TableContainer>
        <TableHeader
          sortByListItemIndex={sortByListItemIndex}
          sortData={sortUsers}
          tableName={TableRowGrid.DelegateAccounts}
          parametersForSorting={USERS_SORT_BY_FE}
        />
        {data && (
          <div>
            {data.map((item) => (
              <DelegateContainerRow
                item={item}
                key={item.id}
                openModalWindow={() => switchFromDetailsToEditModal(item)}
              />
            ))}
          </div>
        )}
      </TableContainer>
      <Pagination
        dataAmount={dataAmount}
        currentPage={page}
        maxPages={maxPages}
        changeActivePage={setPage}
      />
      {isEditingModalOpen && dataForModal && (
        <EditAccessAccountModal
          accessAccount={dataForModal}
          closeModalWindow={toggleEditingModalWindow}
        />
      )}
    </>
  );
};

export default React.memo(SubscribedTable);
