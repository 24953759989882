import React, { useCallback, useMemo, useState } from 'react';

import { PAGE_SIZES, TableRowGrid } from 'components/table/models';
import Pagination from 'components/table/Pagination';
import { TableContainer } from 'components/table/styledElements/tableStyledElements';
import TableHeader from 'components/table/TableHeader';
import calculateMaxPagesNumber from 'helpers/calculateMaxPagesNumber';
import { cleanAccountLocalStorage } from 'helpers/connectToLocalStorage';
import OnboardingTableInfo from 'modules/onboarding/components/OnboardingTableInfo';
import OnboardingOfferContextProvider from 'modules/onboarding/context/OnboardingOfferContext';
import {
  OnboardingOfferItem,
  parametersForSortingUsersFE,
} from 'modules/onboarding/models';
import { arrayOnNavigationItems } from 'modules/onboarding/pages/Onboarding';

import EditOnboardingModal from './EditLandingModal';
import LandingContainerRow from './LandingContainerRow';

type OnboardingOffersTableProps = {
  data?: OnboardingOfferItem[];
  pageSize: number;
  dataAmount: number;
  sortByListItemIndex: number;
  activeNavigationItem: string;
  sortUsers: (index: number) => void;
  changePageSize: (pageSize: number) => void;
  chooseActiveItem: (i: number) => void;
};

const LandingTable = ({
  data,
  pageSize,
  activeNavigationItem,
  sortUsers,
  chooseActiveItem,
  changePageSize,
  dataAmount,
  sortByListItemIndex,
}: OnboardingOffersTableProps) => {
  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);
  const [dataForModal, setDataForModal] = useState<OnboardingOfferItem>();

  const [page, setPage] = useState(1);
  const maxPages = useMemo(
    () => calculateMaxPagesNumber(dataAmount, pageSize),
    [dataAmount, pageSize]
  );

  const toggleEditingModalWindow = useCallback(() => {
    setIsEditingModalOpen(!isEditingModalOpen);
    cleanAccountLocalStorage();
  }, [isEditingModalOpen]);

  const switchFromDetailsToEditModal = useCallback(
    (data: OnboardingOfferItem) => {
      setDataForModal(data);
      toggleEditingModalWindow();
    },
    [toggleEditingModalWindow]
  );

  return (
    <>
      <OnboardingTableInfo
        navigationList={arrayOnNavigationItems}
        employersPage
        pageSizes={PAGE_SIZES}
        activePageSize={pageSize}
        changePageSize={changePageSize}
        chooseActiveItem={chooseActiveItem}
        activeNavigationItem={activeNavigationItem}
      />
      <TableContainer>
        <TableHeader
          sortByListItemIndex={sortByListItemIndex}
          sortData={sortUsers}
          tableName={TableRowGrid.LandingUsers}
          parametersForSorting={parametersForSortingUsersFE}
        />
        {data && (
          <div>
            {data.map((item) => (
              <OnboardingOfferContextProvider key={item.id}>
                <LandingContainerRow
                  item={item}
                  openEditModalWindow={switchFromDetailsToEditModal}
                />
              </OnboardingOfferContextProvider>
            ))}
          </div>
        )}
      </TableContainer>
      <Pagination
        dataAmount={dataAmount}
        currentPage={page}
        maxPages={maxPages}
        changeActivePage={setPage}
      />
      {isEditingModalOpen && dataForModal && (
        <OnboardingOfferContextProvider>
          <EditOnboardingModal
            offer={dataForModal}
            closeModalWindow={toggleEditingModalWindow}
          />
        </OnboardingOfferContextProvider>
      )}
    </>
  );
};

export default React.memo(LandingTable);
