import { find } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Sticky from 'react-sticky-el';
import theme from 'theme';

import Header from 'components/detailsModal/Header';
import PromotionInformation from 'components/detailsModal/information/PromotionInformation';
import ModalAction from 'components/detailsModal/ModalAction';
import ModalNavigation from 'components/detailsModal/ModalNavigation';
import {
  CLOSE_DETAILS_MODAL_TIMER,
  RequestStatus,
  StatusBE,
  StatusFE,
} from 'components/detailsModal/models';
import {
  DetailsModalContainer,
  DetailsModalContent,
} from 'components/detailsModal/styledElements/detailsModalStyledElements';
import SuccessStage from 'components/detailsModal/SuccessStage';
import { promotionTerms } from 'helpers/convertPromotionTerms';
import useOutsideClick from 'hooks/useOutsideClick';
import usePrevious from 'hooks/usePrevious';
import { deletePromotionOffer } from 'modules/offers/offersServices/PromotionOffersService';

import { PromotionOfferResponse, QueryKey } from '../../models';

import { generatePromotionPageUrl } from './helpers/generatePromotionUrls';

type Props = {
  offer: PromotionOfferResponse;
  isOpen: boolean;
  closeWindow: () => void;
  setDataForEditing: (data: PromotionOfferResponse) => void;
  openEditingModalWindow: () => void;
};

const STATUSES_FE = [StatusFE.Active, StatusFE.Delete];

const PromotionDetailsModal = ({
  offer,
  isOpen,
  closeWindow,
  setDataForEditing,
  openEditingModalWindow,
}: Props) => {
  const [activeStatus, setActiveStatus] = useState(StatusFE.Active);
  const [successTitle, setSuccessTitle] = useState('');
  const [successText, setSuccessText] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [requestStatus, setRequestStatus] = useState<RequestStatus | null>(
    null
  );
  const [isActionConfirmed, setIsActionConfirmed] = useState(false);
  const previousActiveStatus = usePrevious(activeStatus);
  const modalRef = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(deletePromotionOffer, {
    onSuccess: () => {
      setRequestStatus(RequestStatus.Success);
      queryClient.invalidateQueries(QueryKey.GetPromotionOffers);
    },
    onError: () => {
      setRequestStatus(RequestStatus.Fail);
      setSuccessTitle('Something went wrong :(');
    },
  });

  const onDelete = useCallback(() => {
    setSuccessTitle('deleted');
    setSuccessText('This page has been removed from shido.me');
    mutate({
      promotionOfferId: offer.id,
    });
  }, [mutate, offer.id]);

  const toggleDeleteStatus = useCallback(() => {
    setIsActionConfirmed(!isActionConfirmed);

    if (!isActionConfirmed) {
      setBackgroundColor(theme.colors.hibiscus);
    } else {
      setBackgroundColor('');
    }
  }, [isActionConfirmed]);

  useOutsideClick(modalRef, closeWindow);

  useEffect(() => {
    if (previousActiveStatus !== activeStatus) {
      setIsActionConfirmed(false);
      setBackgroundColor('');
    }
  }, [activeStatus, offer, previousActiveStatus]);

  useEffect(() => {
    if (requestStatus) {
      setTimeout(() => {
        closeWindow();
        setRequestStatus(null);
        setSuccessText('');
        setSuccessTitle('');
      }, CLOSE_DETAILS_MODAL_TIMER);
    }
  }, [requestStatus, successTitle, closeWindow]);

  useEffect(() => {
    const { status } = offer;

    if (status === StatusBE.Active) {
      setActiveStatus(StatusFE.Active);
    }
  }, [offer]);

  const term = useMemo(
    () => find(promotionTerms, { findBy: offer.promotionTerm.term }),
    [offer.promotionTerm.term]
  );

  const isSuccessStageOpen = isLoading || requestStatus;

  const detailsName = useMemo(
    () =>
      offer.publicCode.toLowerCase().charAt(0) +
      offer.publicCode.toLowerCase().slice(1),
    [offer.publicCode]
  );

  return (
    <DetailsModalContainer ref={modalRef} $shouldRender={isOpen}>
      <Sticky
        topOffset={-80}
        stickyStyle={{
          top: 85,
        }}
      >
        <DetailsModalContent $backgroundColor={backgroundColor}>
          {isSuccessStageOpen ? (
            <SuccessStage
              name={detailsName}
              requestStatus={requestStatus}
              text={successText}
              title={successTitle}
            />
          ) : (
            <>
              <Header
                name={detailsName}
                closeModal={closeWindow}
                openEditingModalWindow={() => {
                  openEditingModalWindow();
                  setDataForEditing(offer);
                }}
              />
              <ModalNavigation
                parameters={STATUSES_FE}
                activeStatus={activeStatus}
                setActiveStatus={(index: number) =>
                  setActiveStatus(STATUSES_FE[index])
                }
              />
              {activeStatus === StatusFE.Active && (
                <PromotionInformation
                  color={backgroundColor}
                  settings={{
                    terms: term?.promoDisplay as string,
                    redemptions: String(offer.redemptions),
                    valid: '20 Oct 21 - 20 Nov 2021',
                  }}
                  offerPageInfo={{
                    qrCodeUrl: offer.qrCodeUrl,
                    publicCode: offer.publicCode,
                    url: generatePromotionPageUrl(
                      offer.publicCode,
                      term?.shidoMeUrl
                    ),
                  }}
                />
              )}
              {activeStatus === StatusFE.Delete && (
                <ModalAction
                  buttonText="delete"
                  isChecked={isActionConfirmed}
                  color={backgroundColor}
                  submitAction={onDelete}
                  closeModal={closeWindow}
                  toggleStatus={toggleDeleteStatus}
                  actionTypeText="YES DELETE THIS promotion"
                  note="Remove all pages and assets"
                />
              )}
            </>
          )}
        </DetailsModalContent>
      </Sticky>
    </DetailsModalContainer>
  );
};

export default PromotionDetailsModal;
