import { AxiosProgressEvent } from 'axios';
import config from 'config';

import { axiosClient } from 'axiosClient/axiosClient';
import { ERROR_IMAGE_MESSAGE } from 'components/actionModal/forms/uploadOfferPageField/UploadOfferPageField';
import { REMOVE_ERROR_TIMER } from 'components/actionModal/models';
import { StatusBE } from 'components/detailsModal/models';
import getExactTime from 'helpers/getExactTime';
import { CorporateAccount } from 'modules/accounts/accountsServices/CorporateAccountsService';

export enum CorporateAudienceType {
  General = 'general',
  Employee = 'employee',
}

export type CorporateSettings = {
  audienceType: CorporateAudienceType;
  includeDepartment: string;
  publicCode: string;
  code: string;
  unitPriceCurrency: string;
  unitPrice: number;
  redemptionLimit: number;
  startDateUTC: Date | null;
  endDateUTC: Date | null;
  offerTerm: string;
  notes?: string;
};

export interface CorporateOffer extends CorporateSettings {
  id: string;
  redemptions: number;
  logoUrl: string;
  backgroundUrl: string;
  status: StatusBE;
  createdDate: string;
  corporateAccountId: string;
  companyName: string;
  corporateAccount: CorporateAccount;
}

interface CorporateOfferDraft extends CorporateSettings {
  corporateAccountId: string;
}

type CreateCorporateOfferDraftPayload = {
  controller: AbortController;
  data: CorporateOfferDraft;
};

export const createCorporateOfferDraft = async ({
  data,
  controller,
}: CreateCorporateOfferDraftPayload): Promise<CorporateOffer> => {
  const [hours, minutes, seconds, milliseconds] = getExactTime();

  const response = await axiosClient.post(
    `/api/v${config.apiVersionFive}/CorporateOffers/CreateDraft`,
    {
      ...data,
      // set new time to pass validation on BE
      startDateUTC: new Date(
        (data.startDateUTC as Date).setHours(
          hours,
          minutes,
          seconds,
          milliseconds
        )
      ),
    },
    {
      signal: controller.signal,
    }
  );

  return response.data.payload.data;
};

type ImageDraftPayload = {
  id: string;
  options: {
    onUploadProgress: (progress: AxiosProgressEvent) => void;
  };
  formData: FormData;
  openError: (value: boolean) => void;
  setErrorText: (value: string) => void;
};

export const uploadLogoDraft = ({
  id,
  options,
  formData,
  openError,
  setErrorText,
}: ImageDraftPayload) =>
  axiosClient
    .put(
      `/api/v${config.apiVersion}/CorporateOffers/${id}/UploadLogoDraft`,
      formData,
      {
        ...options,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then((response) => response.data.payload)
    .catch(() => {
      openError(true);
      setErrorText(ERROR_IMAGE_MESSAGE);
      setTimeout(() => {
        openError(false);
      }, REMOVE_ERROR_TIMER);
    });

export const uploadBackgroundDraft = ({
  id,
  options,
  formData,
  openError,
  setErrorText,
}: ImageDraftPayload) =>
  axiosClient
    .put(
      `/api/v${config.apiVersion}/CorporateOffers/${id}/UploadBackgroundDraft`,
      formData,
      {
        ...options,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then((response) => response.data.payload)
    .catch(() => {
      openError(true);
      setErrorText(ERROR_IMAGE_MESSAGE);

      setTimeout(() => {
        openError(false);
      }, REMOVE_ERROR_TIMER);
    });

export enum ImageType {
  Logo = 1,
  Background = 2,
}

export const markImageAsDeleted = ({
  id,
  type,
}: {
  id: string;
  type: ImageType;
}) =>
  axiosClient
    .post(
      `/api/v${config.apiVersionFive}/CorporateOffers/mark-image-as-deleted?imageTypeViewModel=${type}&corporateOfferId=${id}`
    )
    .then((response) => response.data.payload);

export const unmarkImageAsDeleted = ({
  id,
  type,
}: {
  id: string;
  type: ImageType;
}) =>
  axiosClient
    .post(
      `/api/v${config.apiVersionFive}/CorporateOffers/unmark-image-as-deleted?imageTypeViewModel=${type}&corporateOfferId=${id}`
    )
    .then((response) => response.data.payload);

export type PageInfo = {
  page: number;
  pageSize: number;
  sortBy: string;
  search: string;
};

type CorporateOffersPayload = {
  data: PageInfo;
};

export type CorporateOffersResponse = {
  page: number;
  pageSize: number;
  totalCount: number;
  items: CorporateOffer[];
};

export const getCorporateOffers = async ({
  data,
}: CorporateOffersPayload): Promise<CorporateOffersResponse> => {
  const response = await axiosClient.post<
    CorporateOffersPayload,
    { data: { payload: { corporateOffers: CorporateOffersResponse } } }
  >(`/api/v${config.apiVersion}/CorporateOffers/GetPage`, data);

  return {
    ...response.data.payload.corporateOffers,
    items: response.data.payload.corporateOffers.items.map((item) => ({
      ...item,
      audienceType: item.audienceType
        ? item.audienceType
        : CorporateAudienceType.General,
    })),
  };
};

export type PublishCorporateOfferPayload = {
  id: string;
};

export const publishCorporateOffer = ({ id }: PublishCorporateOfferPayload) =>
  axiosClient
    .put(`/api/v${config.apiVersion}/CorporateOffers/${id}/Publish`, null)
    .then((response) => response.data.payload);

type DeleteCorporateOfferPayload = {
  corporateOfferId: string;
  controller?: AbortController;
};

export const deleteCorporateOffer = ({
  corporateOfferId,
  controller,
}: DeleteCorporateOfferPayload) =>
  axiosClient
    .delete(`/api/v${config.apiVersion}/CorporateOffers/${corporateOfferId}`, {
      signal: controller?.signal,
    })
    .then((response) => response.data.payload);

type SuspendCorporateOfferPayload = {
  corporateOfferId: string;
};

export const suspendCorporateOffer = ({
  corporateOfferId,
}: SuspendCorporateOfferPayload) =>
  axiosClient.put(
    `/api/v${config.apiVersion}/CorporateOffers/${corporateOfferId}/Suspend`,
    null
  );

type ActivateCorporateOfferPayload = {
  corporateOfferId: string;
};

export const activateCorporateOffer = ({
  corporateOfferId,
}: ActivateCorporateOfferPayload) =>
  axiosClient
    .put(
      `/api/v${config.apiVersion}/CorporateOffers/${corporateOfferId}/Activate`,
      null
    )
    .then((response) => response.data.payload);

type CorporateEditSettings = {
  audienceType: CorporateAudienceType;
  unitPrice: number;
  unitPriceCurrency: string;
  redemptionLimit: number;
  endDateUTC: string | Date | null;
  offerTerm: string;
  isLogoUpdated: boolean;
  isBackgroundUpdated: boolean;
  isLogoDeleted: boolean;
  isBackgroundDeleted: boolean;
};

export type EditCorporateOfferPayload = {
  corporateOfferId: string;
  data: CorporateEditSettings;
};

export const editCorporateOffer = ({
  corporateOfferId,
  data,
}: EditCorporateOfferPayload) =>
  axiosClient
    .put(`/api/v${config.apiVersion}/CorporateOffers/${corporateOfferId}`, data)
    .then((response) => response.data.payload);
