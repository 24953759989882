import config from 'config';
import { find } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import DesktopPreview from 'components/previewPages/previewPagePromotion/DesktopPreview';
import { colorPicker } from 'components/previewPages/previewPagePromotion/PreviewPagePromotion';
import { PAGE_SIZES, TableRowGrid } from 'components/table/models';
import Pagination from 'components/table/Pagination';
import { TableContainer } from 'components/table/styledElements/tableStyledElements';
import TableHeader from 'components/table/TableHeader';
import TableInfo from 'components/table/TableInfo';
import TableRow from 'components/table/tableRow/TableRow';
import calculateMaxPagesNumber from 'helpers/calculateMaxPagesNumber';
import { cleanOfferLocalStorage } from 'helpers/connectToLocalStorage';
import { promotionTerms } from 'helpers/convertPromotionTerms';
import PromotionOfferContextProvider from 'modules/offers/context/PromotionOfferContext';

import { OFFERS_SORT_BY_FE, PromotionOfferResponse } from '../../models';

import EditPromotionModal from './EditPromotionModal';
import { generatePromotionPageUrl } from './helpers/generatePromotionUrls';
import PromotionDetailsModal from './PromotionDetailsModal';

type Props = {
  data?: PromotionOfferResponse[];
  dataAmount: number;
  page: number;
  activePageSize: number;
  sortByListItemIndex: number;
  sortOffers: (index: number) => void;
  setActivePageSize: (pageSize: number) => void;
  setPage: (pageNumber: number) => void;
};

const PromotionTable = ({
  data,
  dataAmount,
  activePageSize,
  setActivePageSize,
  page,
  setPage,
  sortOffers,
  sortByListItemIndex,
}: Props) => {
  const [dataForModal, setDataForModal] =
    useState<PromotionOfferResponse | null>(data?.[0] || null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);
  const [offerForEditing, setOfferForEditing] = useState<
    PromotionOfferResponse | undefined
  >();

  const maxPages = useMemo(
    () => calculateMaxPagesNumber(dataAmount, activePageSize),
    [activePageSize, dataAmount]
  );

  const closeModalWindow = useCallback(() => setIsModalOpen(false), []);

  const toggleEditingModalWindow = useCallback(() => {
    setIsEditingModalOpen((prev) => !prev);
    cleanOfferLocalStorage();
  }, []);

  const openModalWindow = (data: PromotionOfferResponse) => {
    setIsModalOpen(true);
    setDataForModal(data);
  };

  const chosenColor = useMemo(
    () => colorPicker[dataForModal?.backgroundColour || 'Sand'],
    [dataForModal?.backgroundColour]
  );

  const term = useMemo(
    () => find(promotionTerms, { findBy: dataForModal?.promotionTerm.term }),
    [dataForModal?.promotionTerm.term]
  );

  return (
    <>
      <TableInfo
        pageSizes={PAGE_SIZES}
        dataAmount={dataAmount}
        activePageSize={activePageSize}
        changePageSize={setActivePageSize}
      />
      {dataForModal && (
        <ContainerDesktop className="download" $color={chosenColor.light}>
          <DesktopPreview
            disableInterval
            {...{
              term: term?.promoDisplay,
              qrCodeUrl: dataForModal.qrCodeUrl,
              chosenColor,
              image: dataForModal.imageUrl,
              title: dataForModal.title,
              subtitle: dataForModal.subtitle,
              bodyText: dataForModal.bodyText,
            }}
          />
        </ContainerDesktop>
      )}
      <TableContainer>
        <TableHeader
          sortData={sortOffers}
          sortByListItemIndex={sortByListItemIndex}
          tableName={TableRowGrid.PromotionOffer}
          parametersForSorting={OFFERS_SORT_BY_FE}
        />
        <div>
          {!!data?.length &&
            data.map((item) => {
              const promoUrl = generatePromotionPageUrl(
                item.publicCode,
                find(promotionTerms, { findBy: item.promotionTerm.term })
                  ?.shidoMeUrl
              );

              return (
                <TableRow
                  href={`${config.shidoUrl}${promoUrl}`}
                  key={item.id}
                  data={[
                    promoUrl,
                    item.status,
                    String(item.redemptions),
                    item.notes,
                  ]}
                  tableName={TableRowGrid.PromotionOffer}
                  openModalWindow={() => openModalWindow(item)}
                />
              );
            })}
        </div>
        {dataForModal && (
          <PromotionDetailsModal
            offer={dataForModal}
            isOpen={isModalOpen}
            setDataForEditing={setOfferForEditing}
            closeWindow={closeModalWindow}
            openEditingModalWindow={() => {
              toggleEditingModalWindow();
              closeModalWindow();
            }}
          />
        )}
      </TableContainer>
      <Pagination
        dataAmount={dataAmount}
        currentPage={page}
        maxPages={maxPages}
        changeActivePage={setPage}
      />
      {isEditingModalOpen && offerForEditing && (
        <PromotionOfferContextProvider>
          <EditPromotionModal
            isEditing
            offer={offerForEditing}
            closeModalWindow={toggleEditingModalWindow}
          />
        </PromotionOfferContextProvider>
      )}
    </>
  );
};

const ContainerDesktop = styled.div<{ $color: string }>`
  width: 1820px;
  overflow: hidden;
  background-color: ${({ $color }) => $color};
  position: absolute;
  top: -9999px;
  left: 9999px;
`;

export default PromotionTable;
