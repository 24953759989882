import { capitalize } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import AttentionIcon from 'components/icons/AttentionIcon';
import ShidoIcon from 'components/icons/ShidoIcon';
import Loader from 'components/Loader';
import { LoaderContainer } from 'modules/auth/components/styledElements/formsStyledElements';

import { Title } from '../styledElements/stagesStyledElements';

const CLOSE_MODAL_TIMER = 2000;

type ResultProps = {
  title: string;
  disableAutoClosing?: boolean;
  description?: string;
  closeModal: () => void;
};

const Result = ({
  disableAutoClosing,
  title,
  closeModal,
  description,
}: ResultProps) => {
  const circles = useMemo(
    () => [
      {
        color: theme.colors.careysPink,
        speed: 2,
        size: 184,
      },
      {
        color: theme.colors.careysPink,
        speed: 2.3,
        size: 140,
      },
    ],
    []
  );

  useEffect(() => {
    if (disableAutoClosing || !title) return;

    const timeout = setTimeout(() => {
      closeModal();
    }, CLOSE_MODAL_TIMER);

    return () => {
      clearTimeout(timeout);
    };
  }, [closeModal, disableAutoClosing, title]);

  return (
    <Container>
      <Title $isHidden={!title}>{capitalize(title)}</Title>
      <LoaderContainer>
        <ShidoIcon color={theme.colors.primary} />
        <Loader circles={circles} />
      </LoaderContainer>
      {description && (
        <DescriptionContainer>
          <AttentionIcon />
          <DescriptionText>{description}</DescriptionText>
        </DescriptionContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  text-align: center;
  padding-top: 85px;
  box-sizing: border-box;
  border-radius: 5px;
`;

const DescriptionText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 13px;
  font-weight: 300;
`;

const DescriptionContainer = styled.div`
  margin: auto;
  position: absolute;
  bottom: 107px;
  left: 50%;
  transform: translateX(-50%);
`;

export default Result;
