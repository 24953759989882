import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import theme from 'theme';

import { yupResolver } from '@hookform/resolvers/yup';
import StandardInput from 'components/actionModal/forms/inputs/StandardInput';
import TextAreaField from 'components/actionModal/forms/inputs/TextAreaField';
import { InputId } from 'components/actionModal/models';
import { affiliateOffePageValidationSchema } from 'components/actionModal/models/validationSchemas';
import {
  ModalStage,
  SubmitButtonContainer,
} from 'components/actionModal/styledElements/formsStyledElements';
import DefaultButton from 'components/buttons/DefaultButton';
import { AffiliateOfferContext } from 'modules/offers/context/AffiliateOfferContext';
import useCheckDataChanging from 'modules/offers/hooks/useCheckDataChanging';
import {
  PromotionOfferData,
  AffiliateOfferPageAttributes,
} from 'modules/offers/models';

import { Title } from '../../styledElements/stagesStyledElements';

type OfferPageProps = {
  isShowStage: boolean;
  isEdit?: boolean;
  settings: AffiliateOfferPageAttributes;
  title: string;
  offer?: PromotionOfferData;
  saveSettings: (value: AffiliateOfferPageAttributes) => void;
  sendRequest: (offerPageAttr: AffiliateOfferPageAttributes) => void;
  freezeSettingsPageActivate: (value?: boolean) => void;
};

const AffiliateOfferPage = ({
  isShowStage,
  isEdit,
  settings,
  title,
  saveSettings,
  sendRequest,
  freezeSettingsPageActivate,
}: OfferPageProps) => {
  const {
    handleSubmit,
    watch,
    register,
    setValue,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver(affiliateOffePageValidationSchema),
  });
  const { prevData, isValidFormOffer, setIsValidFormOffer } = useContext(
    AffiliateOfferContext
  );
  const [disableButton, setDisableButton] = useState(false);
  const isFormValid = (isValid || isValidFormOffer) && !disableButton;

  const saveName = useCallback(() => {
    saveSettings({
      ...settings,
      affiliateName: watch()[InputId.AffiliateName],
    });
  }, [saveSettings, settings, watch]);

  const saveStatement = useCallback(
    () =>
      saveSettings({
        ...settings,
        affiliateStatement: watch()[InputId.AffiliateTitle] || '',
      }),
    [saveSettings, settings, watch]
  );

  const saveIntro = useCallback(
    () =>
      saveSettings({
        ...settings,
        intro: watch()[InputId.AffiliateIntro],
      }),
    [saveSettings, settings, watch]
  );

  const saveBody = useCallback(
    () =>
      saveSettings({
        ...settings,
        bodyText: watch()[InputId.AffiliateBody],
      }),
    [saveSettings, settings, watch]
  );

  const formSubmit = useCallback(() => {
    if (isValid) {
      sendRequest({
        affiliateName: watch()[InputId.AffiliateName],
        affiliateStatement: watch()[InputId.AffiliateTitle] || '',
        intro: watch()[InputId.AffiliateIntro],
        bodyText: watch()[InputId.AffiliateBody],
        affiliateHandle: '',
      });
    }
  }, [isValid, sendRequest, watch]);

  const submitForm = handleSubmit(formSubmit);

  useEffect(() => {
    setIsValidFormOffer(false);

    return () => {
      if (isValid) {
        setIsValidFormOffer(true);
      }
    };
  }, [isValid, setIsValidFormOffer]);

  useCheckDataChanging(
    freezeSettingsPageActivate,
    setDisableButton,
    isValid,
    prevData,
    settings,
    isEdit
  );

  return (
    <ModalStage $isShowStage={isShowStage}>
      <StyledTitle>{title}</StyledTitle>
      <FormContainer onSubmit={submitForm}>
        <StandardInput
          errors={errors}
          register={register}
          setValue={setValue}
          label="Affiliate name"
          inputId={InputId.AffiliateName}
          inputValue={settings.affiliateName}
          saveData={saveName}
        />
        <StandardInput
          errors={errors}
          register={register}
          setValue={setValue}
          label="Title"
          inputId={InputId.AffiliateTitle}
          inputValue={settings.affiliateStatement}
          saveData={saveStatement}
        />
        <StandardInput
          errors={errors}
          register={register}
          setValue={setValue}
          label="Intro"
          inputId={InputId.AffiliateIntro}
          inputValue={settings.intro}
          saveData={saveIntro}
        />
        <TextAreaField
          submit={() => {
            if (isFormValid) {
              submitForm();
            }
          }}
          maxRows={3}
          errors={errors}
          register={register}
          setValue={setValue}
          label="Body text"
          inputId={InputId.AffiliateBody}
          inputValue={settings.bodyText}
          saveData={saveBody}
        />
        <SubmitButtonContainer>
          <DefaultButton
            text="next"
            textColor={theme.colors.white}
            isSubmit
            withShadow
            isUppercase
            isActive={isFormValid}
            buttonColor={
              isFormValid ? theme.colors.funtainBlue : theme.colors.quillGray
            }
            buttonSize="md"
            borderSize="md"
          />
        </SubmitButtonContainer>
      </FormContainer>
    </ModalStage>
  );
};

const StyledTitle = styled(Title)`
  margin-bottom: 20px;
`;

const FormContainer = styled.form`
  max-width: 656px;
  margin-top: 19px;
`;

export default React.memo(AffiliateOfferPage);
