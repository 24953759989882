import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import Header from 'components/detailsModal/Header';
import ModalAction from 'components/detailsModal/ModalAction';
import {
  CLOSE_DETAILS_MODAL_TIMER,
  RequestStatus,
  StatusFE,
} from 'components/detailsModal/models';
import SuccessStage from 'components/detailsModal/SuccessStage';
import { HorizontalLine } from 'components/Divider';
import usePrevious from 'hooks/usePrevious';
import { ModalContainer } from 'modules/onboarding/components/styledElements/styledModalElements';

import { EditableDelegateAttributes } from '../models';
import { DelegateAccountsContext } from '../pages/Delegates';

import DelegateAcountInformation from './DelegateModalInformation';
import ModalNavigation from './ModalNavigation';

export const statusesFE = [StatusFE.Active, StatusFE.Delete];

type DelegateAccountDetailsModalProps = {
  delegateAccountInfo: EditableDelegateAttributes;
  closeWindow: () => void;
  openEditingModalWindow: () => void;
};

const DelegateAccountRowDetailsModal = ({
  delegateAccountInfo,
  closeWindow,
  openEditingModalWindow,
}: DelegateAccountDetailsModalProps) => {
  const [requestStatus, setRequestStatus] = useState<RequestStatus | null>(
    null
  );
  const [isActionConfirmed, setIsActionConfirmed] = useState(false);
  const [activeStatus, setActiveStatus] = useState(StatusFE.Active);
  const [backgroundColor, setBackgroundColor] = useState('');
  const previousActiveStatus = usePrevious(activeStatus);
  const [successText, setSuccessText] = useState('');

  const { deleteUser } = useContext(DelegateAccountsContext);

  const onDelete = useCallback(() => {
    setSuccessText('Delegate deleted!');
    setRequestStatus(RequestStatus.Success);
  }, []);

  const closeModal = useCallback(() => closeWindow(), [closeWindow]);

  const changeActiveStatus = useCallback(
    (index: number) => setActiveStatus(statusesFE[index]),
    []
  );

  const toggleDeleteStatus = useCallback(() => {
    setIsActionConfirmed(!isActionConfirmed);

    if (!isActionConfirmed) {
      setBackgroundColor(theme.colors.hibiscus);
    } else {
      setBackgroundColor('');
    }
  }, [isActionConfirmed]);

  useEffect(() => {
    if (previousActiveStatus !== activeStatus) {
      setIsActionConfirmed(false);
      setBackgroundColor('');
    }
  }, [activeStatus, previousActiveStatus, delegateAccountInfo.status]);

  useEffect(() => {
    if (requestStatus) {
      setTimeout(() => {
        deleteUser(delegateAccountInfo.id);
        closeModal();
      }, CLOSE_DETAILS_MODAL_TIMER);
    }
  }, [
    closeModal,
    closeWindow,
    delegateAccountInfo.id,
    deleteUser,
    requestStatus,
  ]);

  return (
    <ModalContainer $background={backgroundColor}>
      {requestStatus ? (
        <SuccessStageWrapper>
          <SuccessStage requestStatus={requestStatus} text={successText} />
        </SuccessStageWrapper>
      ) : (
        <>
          <HeaderWrapper>
            <Header
              name=""
              closeModal={closeModal}
              openEditingModalWindow={openEditingModalWindow}
            />
          </HeaderWrapper>
          <PageTitle>Delegate</PageTitle>
          <NameTitle>
            {delegateAccountInfo.firstName} {delegateAccountInfo.lastName}
          </NameTitle>
          <>
            <ModalNavigation
              parameters={statusesFE}
              activeStatus={activeStatus}
              setActiveStatus={changeActiveStatus}
            />
            <LineWrapper>
              <HorizontalLine />
            </LineWrapper>
          </>
          {activeStatus === StatusFE.Active && (
            <DelegateAcountInformation
              mobile={delegateAccountInfo.mobileNumber}
              email={delegateAccountInfo.email}
              jobTitle={delegateAccountInfo.job}
              role={delegateAccountInfo.role}
            />
          )}
          {activeStatus === StatusFE.Delete && (
            <ModalActionWrapper>
              <ModalAction
                employerModal
                buttonText="delete"
                isChecked={isActionConfirmed}
                color={backgroundColor}
                submitAction={onDelete}
                closeModal={closeModal}
                toggleStatus={toggleDeleteStatus}
                actionTypeText="YES DELETE THIS DELEGATE"
                note="The user will no longer have access to the portal"
              />
            </ModalActionWrapper>
          )}
        </>
      )}
    </ModalContainer>
  );
};

const SuccessStageWrapper = styled.div`
  h4 {
    display: none;
  }

  p {
    margin-top: 60px;
    font-size: 30px;
  }

  div {
    transform: translate(-50%, 36%);
  }
`;

const HeaderWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 20px;

  h2 {
    display: none;
  }

  button:nth-child(1) {
    svg {
      height: 24px;
      width: 24px;
    }
  }
`;

const LineWrapper = styled.div`
  margin-top: 18px;
  width: 630px;
`;

const ModalActionWrapper = styled.div`
  width: 407px;
  margin-top: -5px;

  div:nth-child(2) {
    bottom: 16px;
    column-gap: 6px;
  }
`;

const NameTitle = styled.div`
  font-size: 30px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 14px;
  margin-bottom: 20px;
`;

const PageTitle = styled.div`
  margin-top: 42px;
  font-size: 16px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.white};
`;

export default DelegateAccountRowDetailsModal;
