import config from 'config';
import React, { useCallback, useMemo, useState } from 'react';

import { PAGE_SIZES, TableRowGrid } from 'components/table/models';
import Pagination from 'components/table/Pagination';
import { TableContainer } from 'components/table/styledElements/tableStyledElements';
import TableHeader from 'components/table/TableHeader';
import TableInfo from 'components/table/TableInfo';
import TableRow from 'components/table/tableRow/TableRow';
import calculateMaxPagesNumber from 'helpers/calculateMaxPagesNumber';
import { cleanOfferLocalStorage } from 'helpers/connectToLocalStorage';
import AffiliateOfferContextProvider from 'modules/offers/context/AffiliateOfferContext';

import {
  AffiliateOfferResponse,
  ConversionFunnelBE,
  AFFILIATE_SORT_BY_FE,
} from '../../models';

import AffiliateDetailsModal from './AffiliateDetailsModal';
import EditAffiliateModal from './EditAffiliateModal';

type Props = {
  data?: AffiliateOfferResponse[];
  dataAmount: number;
  page: number;
  activePageSize: number;
  sortByListItemIndex: number;
  isOfferPublished: boolean;
  sortOffers: (index: number) => void;
  setActivePageSize: (pageSize: number) => void;
  setPage: (pageNumber: number) => void;
};

const AffiliateTable = ({
  data,
  dataAmount,
  activePageSize,
  isOfferPublished,
  setActivePageSize,
  page,
  setPage,
  sortOffers,
  sortByListItemIndex,
}: Props) => {
  const [dataForModal, setDataForModal] =
    useState<AffiliateOfferResponse | null>(data?.[0] || null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);
  const [offerForEditing, setOfferForEditing] = useState<
    AffiliateOfferResponse | undefined
  >();

  const maxPages = useMemo(
    () => calculateMaxPagesNumber(dataAmount, activePageSize),
    [activePageSize, dataAmount]
  );

  const closeModalWindow = useCallback(() => setIsModalOpen(false), []);

  const toggleEditingModalWindow = useCallback(() => {
    setIsEditingModalOpen((prev) => !prev);
    cleanOfferLocalStorage();
    closeModalWindow();
  }, [closeModalWindow]);

  const openModalWindow = (data: AffiliateOfferResponse) => {
    setIsModalOpen(true);
    setDataForModal(data);
  };

  return (
    <>
      <TableInfo
        pageSizes={PAGE_SIZES}
        dataAmount={dataAmount}
        activePageSize={activePageSize}
        changePageSize={setActivePageSize}
      />
      <TableContainer>
        <TableHeader
          sortData={sortOffers}
          sortByListItemIndex={sortByListItemIndex}
          tableName={TableRowGrid.AffiliateOfferRow}
          parametersForSorting={AFFILIATE_SORT_BY_FE}
        />
        <div>
          {!!data?.length &&
            data.map((item, i) => (
              <TableRow
                key={item.id}
                isPublished={i === 0 && isOfferPublished}
                href={`${config.shidoUrl}affiliate/${encodeURIComponent(
                  item.publicCode
                )}`}
                data={[
                  item.affiliateName,
                  item.conversionFunnel === ConversionFunnelBE.App
                    ? 'App code'
                    : 'Checkout',
                  item.redemptions || 0,
                  item.convertedRedemptionsThisMonth || 0,
                ]}
                tableName={TableRowGrid.AffiliateOfferRow}
                openModalWindow={() => openModalWindow(item)}
              />
            ))}
        </div>
        {dataForModal && (
          <AffiliateDetailsModal
            offer={dataForModal}
            isOpen={isModalOpen}
            setDataForEditing={setOfferForEditing}
            closeWindow={closeModalWindow}
            openEditingModalWindow={toggleEditingModalWindow}
          />
        )}
      </TableContainer>
      <Pagination
        dataAmount={dataAmount}
        currentPage={page}
        maxPages={maxPages}
        changeActivePage={setPage}
      />
      {isEditingModalOpen && offerForEditing && (
        <AffiliateOfferContextProvider>
          <EditAffiliateModal
            isEditing
            offer={offerForEditing}
            closeModalWindow={toggleEditingModalWindow}
          />
        </AffiliateOfferContextProvider>
      )}
    </>
  );
};

export default AffiliateTable;
