export default {
  namePattern: /^[a-z\s'-]*$/i,
  companyPattern: /^[a-z.!#$%&'*+-\\/=?^_()`{|}~\s]*$/i,
  websitePattern:
    /^(https?:\/\/)?([a-z0-9](-?[a-z0-9]){0,62}\.)+[a-z]{2,63}\/?$/i,
  urlPattern: /\w*[a-z]\w*/gi,
  emailPattern:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  minNumberPattern: /^[^0]|[^0.]/g,
  unitPricePattern: /^[1-9]\d*(\.\d{0,2})?$|^[0](\.\d{0,2})?$|^$/g,
  redemptionLimitPattern: /^[0-9]\d*$|^$/g,
  offerCodePattern: /[\w#$&+=:?@!%^*()\-_`~{}[\]|\\'"";<>.,/]/i,
  passCodePattern: /[0-9]/,
  passwordUpperCase: /[A-Z]/,
  passwordNumberExist: /\d/,
};
