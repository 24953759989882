export enum LocalStorageName {
  CorporateAccountDetails = 'CORPORATE_ACCOUNT_DETAILS',
  PrimaryContact = 'PRIMARY_CONTACT',
  ReferrerAccountDetails = 'REFERRER_ACCOUNT_DETAILS',
  AccountId = 'COMPANY_ID',
  CorporateSettings = 'CORPORATE_SETTINGS',
  CorporateOfferCodePage = 'CORPORATE_OFFER_CODE_PAGE',
  PromotionOfferCodes = 'PROMOTION_OFFER_CODES',
  PromotionSettings = 'PROMOTION_SETTINGS',
  PromotionOfferCodePage = 'PROMOTION_OFFER_CODE_PAGE',
}

export enum InputId {
  CompanyNameInputId = 'COMPANY_NAME_INPUT_ID',
  WebsiteInputId = 'WEBSITE_INPUT_ID',
  EmailAddressInputId = 'EMAIL_ADDRESS_INPUT_ID',
  FirstNameInputId = 'FIRST_NAME_INPUT_ID',
  LastNameInputId = 'LAST_NAME_INPUT_ID',
  MobileNumberInputId = 'MOBILE_NUMBER_INPUT_ID',
  RedemptionLimitInputId = 'REDEMPTION_LIMIT_INPUT_ID',
  UrlInputId = 'URL_INPUT_ID',
  RoleInputId = 'ROLE_INPUT_ID',
  PassCode = 'PASSCODE',
  JobTitleInputId = 'JOB_TITLE_INPUT_ID',
  NoteInputId = 'NOTE_INPUT_ID',
  PromotionTitle = 'PROMOTION_TITLE',
  PromotionSubtitle = 'PROMOTION_SUBTITLE',
  PromotionBody = 'PROMOTION_BODY',
  AffiliateName = 'AFFILIATE_NAME',
  AffiliateTitle = 'AFFILIATE_TITLE',
  AffiliateIntro = 'AFFILIATE_INTRO',
  AffiliateBody = 'AFFILIATE_BODY',
}

export const TXT_FILE_NUMBER_OF_MB = 3;
export const IMG_FILE_NUMBER_OF_MB = 5;
export const REMOVE_ERROR_TIMER = 2000;
export const BYTES_IN_ONE_MB = 1048576;

export enum ErrorResponseFE {
  MOBILE_TAKEN = 'Mobile already taken',
  EMAIL_TAKEN = 'Email already taken',
  MOBILE_INVALID = 'Mobile number is invalid',
}

export type ErrorResponse = {
  MOBILE_TAKEN: string;
  EMAIL_TAKEN: string;
  MOBILE_INVALID: string;
};
