import React, { useMemo, useState } from 'react';

import { PAGE_SIZES, TableRowGrid } from 'components/table/models';
import Pagination from 'components/table/Pagination';
import { TableContainer } from 'components/table/styledElements/tableStyledElements';
import TableHeader from 'components/table/TableHeader';
import calculateMaxPagesNumber from 'helpers/calculateMaxPagesNumber';
import OnboardingTableInfo from 'modules/onboarding/components/OnboardingTableInfo';
import { parametersForSortingUsersFE } from 'modules/onboarding/models';
import { arrayOnNavigationItems } from 'modules/onboarding/pages/Onboarding';

import ManageContainerRow from './ManageContainerRow';
import { ManageUserOnboardingItem } from './models';

type OnboardingOffersTableProps = {
  data?: ManageUserOnboardingItem[];
  pageSize: number;
  dataAmount: number;
  sortByListItemIndex: number;
  activeNavigationItem: string;
  sortUsers: (index: number) => void;
  changePageSize: (pageSize: number) => void;
  chooseActiveItem: (i: number) => void;
};

const ManageTable = ({
  data,
  pageSize,
  activeNavigationItem,
  sortUsers,
  chooseActiveItem,
  changePageSize,
  dataAmount,
  sortByListItemIndex,
}: OnboardingOffersTableProps) => {
  const [page, setPage] = useState(1);
  const maxPages = useMemo(
    () => calculateMaxPagesNumber(dataAmount, pageSize),
    [dataAmount, pageSize]
  );

  return (
    <>
      <OnboardingTableInfo
        navigationList={arrayOnNavigationItems}
        employersPage
        pageSizes={PAGE_SIZES}
        activePageSize={pageSize}
        changePageSize={changePageSize}
        chooseActiveItem={chooseActiveItem}
        activeNavigationItem={activeNavigationItem}
      />
      <TableContainer>
        <TableHeader
          sortByListItemIndex={sortByListItemIndex}
          sortData={sortUsers}
          tableName={TableRowGrid.ManageUsers}
          parametersForSorting={parametersForSortingUsersFE}
        />
        {data && (
          <div>
            {data.map((item) => (
              <ManageContainerRow key={item.name} item={item} />
            ))}
          </div>
        )}
      </TableContainer>
      <Pagination
        dataAmount={dataAmount}
        currentPage={page}
        maxPages={maxPages}
        changeActivePage={setPage}
      />
    </>
  );
};

export default React.memo(ManageTable);
