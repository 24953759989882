import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Sticky from 'react-sticky-el';
import theme from 'theme';

import Header from 'components/detailsModal/Header';
import AffiliateInformation from 'components/detailsModal/information/AffiliateInformation';
import ModalAction from 'components/detailsModal/ModalAction';
import ModalNavigation from 'components/detailsModal/ModalNavigation';
import {
  CLOSE_DETAILS_MODAL_TIMER,
  RequestStatus,
  StatusBE,
  StatusFE,
} from 'components/detailsModal/models';
import {
  DetailsModalContainer,
  DetailsModalContent,
} from 'components/detailsModal/styledElements/detailsModalStyledElements';
import SuccessStage from 'components/detailsModal/SuccessStage';
import useOutsideClick from 'hooks/useOutsideClick';
import usePrevious from 'hooks/usePrevious';
import { deleteAffiliateOffer } from 'modules/offers/offersServices/AffiliateOffersService';

import {
  AffiliateOfferResponse,
  ConversionFunnelBE,
  QueryKey,
} from '../../models';

type Props = {
  offer: AffiliateOfferResponse;
  isOpen: boolean;
  closeWindow: () => void;
  setDataForEditing: (data: AffiliateOfferResponse) => void;
  openEditingModalWindow: () => void;
};

const STATUSES_FE = [StatusFE.Active, StatusFE.Delete];

const AffiliateDetailsModal = ({
  offer,
  isOpen,
  closeWindow,
  setDataForEditing,
  openEditingModalWindow,
}: Props) => {
  const queryClient = useQueryClient();

  const [activeStatus, setActiveStatus] = useState(StatusFE.Active);
  const [successTitle, setSuccessTitle] = useState('');
  const [successText, setSuccessText] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [requestStatus, setRequestStatus] = useState<RequestStatus | null>(
    null
  );
  const [isActionConfirmed, setIsActionConfirmed] = useState(false);

  const previousActiveStatus = usePrevious(activeStatus);

  const modalRef = useRef<HTMLDivElement>(null);

  const { mutate, isLoading } = useMutation(deleteAffiliateOffer, {
    onSuccess: () => {
      setRequestStatus(RequestStatus.Success);
      queryClient.invalidateQueries(QueryKey.GetAffiliateOffers);
    },
    onError: () => {
      setRequestStatus(RequestStatus.Fail);
      setSuccessTitle('Something went wrong :(');
    },
  });

  const onDelete = useCallback(() => {
    setSuccessTitle('deleted');
    setSuccessText('This page has been removed from shido.me');
    mutate({
      affiliateOfferId: offer.id,
    });
  }, [mutate, offer.id]);

  const toggleDeleteStatus = useCallback(() => {
    setIsActionConfirmed(!isActionConfirmed);

    if (!isActionConfirmed) {
      setBackgroundColor(theme.colors.hibiscus);
    } else {
      setBackgroundColor('');
    }
  }, [isActionConfirmed]);

  useOutsideClick(modalRef, closeWindow);

  useEffect(() => {
    if (previousActiveStatus !== activeStatus) {
      setIsActionConfirmed(false);
      setBackgroundColor('');
    }
  }, [activeStatus, offer, previousActiveStatus]);

  useEffect(() => {
    if (requestStatus) {
      const modalClosing = setTimeout(() => {
        closeWindow();
        setRequestStatus(null);
        setSuccessText('');
        setSuccessTitle('');
      }, CLOSE_DETAILS_MODAL_TIMER);

      return () => clearTimeout(modalClosing);
    }
  }, [requestStatus, successTitle, closeWindow]);

  useEffect(() => {
    const { status } = offer;

    if (status === StatusBE.Active) {
      setActiveStatus(StatusFE.Active);
    }
  }, [offer]);

  const isSuccessStageOpen = isLoading || requestStatus;

  return (
    <DetailsModalContainer
      data-testid="affiliate-details-modal"
      ref={modalRef}
      $shouldRender={isOpen}
    >
      <Sticky
        topOffset={-80}
        stickyStyle={{
          top: 85,
        }}
      >
        <DetailsModalContent $backgroundColor={backgroundColor}>
          {isSuccessStageOpen ? (
            <SuccessStage
              name="Affiliate"
              requestStatus={requestStatus}
              text={successText}
              title={successTitle}
            />
          ) : (
            <>
              <Header
                name={offer.affiliateName}
                closeModal={closeWindow}
                openEditingModalWindow={() => {
                  openEditingModalWindow();
                  setDataForEditing(offer);
                }}
              />
              <ModalNavigation
                parameters={STATUSES_FE}
                activeStatus={activeStatus}
                setActiveStatus={(index: number) =>
                  setActiveStatus(STATUSES_FE[index])
                }
              />

              {activeStatus === StatusFE.Active && (
                <AffiliateInformation
                  color={backgroundColor}
                  settings={{
                    id: offer.id,
                    referrals: offer.redemptions,
                    subscribers: offer.convertedRedemptionsThisMonth,
                    publicCode: offer.publicCode,
                    qrCodeUrl: offer.qrCodeUrl,
                    funnel:
                      offer.conversionFunnel === ConversionFunnelBE.App
                        ? 'App code'
                        : 'Stripe checkout',
                  }}
                />
              )}

              {activeStatus === StatusFE.Delete && (
                <ModalAction
                  buttonText="delete"
                  isChecked={isActionConfirmed}
                  color={backgroundColor}
                  submitAction={onDelete}
                  closeModal={closeWindow}
                  toggleStatus={toggleDeleteStatus}
                  actionTypeText="YES DELETE THIS AFFILIATE"
                  note="Remove their page"
                />
              )}
            </>
          )}
        </DetailsModalContent>
      </Sticky>
    </DetailsModalContainer>
  );
};

export default AffiliateDetailsModal;
