import { useEffect } from 'react';

import { departmentVariant } from 'components/listForSelectingData/dataForLists';

import {
  CorporateOffer,
  CorporateSettings,
} from '../offersServices/CorporateOffersService';

const useSaveDataForEditCorporateOffer = (
  corporateOffer: CorporateOffer,
  setSaved: (value: boolean) => void,
  saveSettings: (data: CorporateSettings) => void,
  setLogoImage: (url: string) => void,
  setBackgroundImage: (url: string) => void,
  setLogoName: (name: string) => void,
  setBackgroundName: (name: string) => void,
  setCompanyName: (name: string) => void,
  setisEndDateExists: (value: boolean) => void,
  saved?: boolean
) => {
  useEffect(() => {
    if (!saved) {
      const {
        corporateAccount: { companyName },
        publicCode,
        endDateUTC: endDateString,
        startDateUTC: startDateString,
        offerTerm,
        redemptionLimit,
        unitPrice,
        unitPriceCurrency,
        code,
        backgroundUrl,
        logoUrl,
        notes,
        audienceType,
      } = corporateOffer;

      const endDateUTC = endDateString ? new Date(endDateString) : null;
      const startDateUTC = startDateString ? new Date(startDateString) : null;

      if (endDateUTC) {
        setisEndDateExists(true);
      }

      saveSettings({
        includeDepartment: departmentVariant.Yes,
        publicCode,
        endDateUTC,
        startDateUTC,
        offerTerm,
        redemptionLimit,
        unitPrice,
        unitPriceCurrency,
        code,
        notes,
        audienceType,
      });

      setCompanyName(companyName);
      setBackgroundImage(backgroundUrl);
      setLogoImage(logoUrl);
      setLogoName('logo');
      setBackgroundName('background');

      setSaved(true);
    }
  }, [
    corporateOffer,
    saveSettings,
    saved,
    setSaved,
    setBackgroundImage,
    setLogoImage,
    setLogoName,
    setBackgroundName,
    setCompanyName,
    setisEndDateExists,
  ]);
};

export default useSaveDataForEditCorporateOffer;
