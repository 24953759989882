import { find } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Sticky from 'react-sticky-el';
import theme from 'theme';

import Header from 'components/detailsModal/Header';
import CorporateAccountInformation from 'components/detailsModal/information/CorporateAccountInformation';
import ModalAction from 'components/detailsModal/ModalAction';
import ModalNavigation from 'components/detailsModal/ModalNavigation';
import {
  CLOSE_DETAILS_MODAL_TIMER,
  RequestStatus,
  StatusBE,
  StatusFE,
  statusesFE,
} from 'components/detailsModal/models';
import {
  DetailsModalContainer,
  DetailsModalContent,
} from 'components/detailsModal/styledElements/detailsModalStyledElements';
import SuccessStage from 'components/detailsModal/SuccessStage';
import countries from 'components/listForSelectingData/dataForList/countries';
import useOutsideClick from 'hooks/useOutsideClick';
import usePrevious from 'hooks/usePrevious';

import {
  activateCorporateAccount,
  CorporateAccount,
  deleteCorporateAccount,
  suspendCorporateAccount,
} from '../../accountsServices/CorporateAccountsService';
import useChangeAccountStatus from '../../hooks/useChangeAccountStatus';

type Props = {
  account: CorporateAccount;
  isOpen: boolean;
  closeWindow: () => void;
  setDataForEditing: (data: CorporateAccount) => void;
  openEditingModalWindow: () => void;
};

const CorporateDetailsModal = ({
  account,
  isOpen,
  closeWindow,
  setDataForEditing,
  openEditingModalWindow,
}: Props) => {
  const [requestStatus, setRequestStatus] = useState<RequestStatus | null>(
    null
  );
  const [activeStatus, setActiveStatus] = useState(StatusFE.Active);
  const [isActionConfirmed, setIsActionConfirmed] = useState(false);
  const [successTitle, setSuccessTitle] = useState('');
  const [successText, setSuccessText] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const previousActiveStatus = usePrevious(activeStatus);
  const modalRef = useRef<HTMLDivElement>(null);

  const countryAbbreviation = useMemo(
    () => find(countries, { BEformat: account.country })?.abbr,
    [account.country]
  );

  const deleteAccount = useChangeAccountStatus(
    setSuccessTitle,
    setRequestStatus,
    deleteCorporateAccount
  );
  const suspendAccount = useChangeAccountStatus(
    setSuccessTitle,
    setRequestStatus,
    suspendCorporateAccount
  );
  const activateAccount = useChangeAccountStatus(
    setSuccessTitle,
    setRequestStatus,
    activateCorporateAccount
  );
  const isSuccessStageOpen =
    deleteAccount.isLoading ||
    suspendAccount.isLoading ||
    activateAccount.isLoading ||
    requestStatus;

  const onDelete = useCallback(() => {
    setSuccessTitle('deleted');
    setSuccessText('All assigned offers & assets have been deleted');
    deleteAccount.mutate({
      corporateAccountId: account.id,
    });
  }, [account.id, deleteAccount]);

  const onSuspend = useCallback(() => {
    setSuccessTitle('suspended');
    setSuccessText('All offers have been expired until reactivated');
    suspendAccount.mutate({
      corporateAccountId: account.id,
    });
  }, [account.id, suspendAccount]);

  const onActivate = useCallback(() => {
    setSuccessTitle('activated');
    setSuccessText('All offers have been activated');
    setIsActionConfirmed(false);
    setBackgroundColor('');
    activateAccount.mutate({
      corporateAccountId: account.id,
    });
  }, [account.id, activateAccount]);

  const toggleActivateStatus = useCallback(() => {
    setIsActionConfirmed(!isActionConfirmed);
    setBackgroundColor(
      !isActionConfirmed ? theme.colors.funtainBlue : theme.colors.secondary
    );
  }, [isActionConfirmed]);

  const toggleSuspendStatus = useCallback(() => {
    setIsActionConfirmed(!isActionConfirmed);
    setBackgroundColor(!isActionConfirmed ? theme.colors.secondary : '');
  }, [isActionConfirmed]);

  const toggleDeleteStatus = useCallback(() => {
    setIsActionConfirmed(!isActionConfirmed);

    if (!isActionConfirmed) {
      setBackgroundColor(theme.colors.hibiscus);
    } else if (account.status === StatusBE.Suspend) {
      setBackgroundColor(theme.colors.secondary);
    } else {
      setBackgroundColor('');
    }
  }, [account.status, isActionConfirmed]);

  useOutsideClick(modalRef, closeWindow);

  useEffect(() => {
    if (previousActiveStatus !== activeStatus) {
      setIsActionConfirmed(false);

      if (account.status !== StatusBE.Suspend) {
        setBackgroundColor('');
      } else if (account.status === StatusBE.Suspend) {
        setBackgroundColor(theme.colors.secondary);
      }
    }
  }, [activeStatus, account.status, previousActiveStatus]);

  useEffect(() => {
    if (requestStatus) {
      setTimeout(() => {
        closeWindow();
        setRequestStatus(null);
        setSuccessText('');
        setSuccessTitle('');
      }, CLOSE_DETAILS_MODAL_TIMER);
    }
  }, [requestStatus, successTitle, closeWindow]);

  useEffect(() => {
    const { status } = account;

    if (status === StatusBE.Pending) {
      // create logic for pending status
      setActiveStatus(StatusFE.Active);
    } else if (status === StatusBE.Active) {
      setActiveStatus(StatusFE.Active);
    } else {
      setActiveStatus(StatusFE.Suspend);
      setBackgroundColor(theme.colors.secondary);
    }
  }, [account]);

  return (
    <DetailsModalContainer
      data-testid="corporate-details-modal"
      ref={modalRef}
      $shouldRender={isOpen}
    >
      <Sticky
        topOffset={-80}
        stickyStyle={{
          top: 85,
        }}
      >
        <DetailsModalContent $backgroundColor={backgroundColor}>
          {isSuccessStageOpen ? (
            <SuccessStage
              requestStatus={requestStatus}
              name={account.companyName}
              text={successText}
              title={successTitle}
            />
          ) : (
            <>
              <Header
                name={`${account.companyName}, ${countryAbbreviation}`}
                closeModal={closeWindow}
                openEditingModalWindow={() => {
                  openEditingModalWindow();
                  setDataForEditing(account);
                }}
              />
              <ModalNavigation
                parameters={statusesFE}
                activeStatus={activeStatus}
                setActiveStatus={(index: number) =>
                  setActiveStatus(statusesFE[index])
                }
              />
              {activeStatus === StatusFE.Active && (
                <CorporateAccountInformation
                  color={backgroundColor}
                  closeModal={closeWindow}
                  isChecked={isActionConfirmed}
                  toggleStatus={toggleActivateStatus}
                  accountStatus={account.status}
                  activateAccount={onActivate}
                  offersInAccount={{
                    expiredOffersCount: 0,
                    suspendedOffersCount: 0,
                    activeOffersCount: 1,
                  }}
                  primaryContact={{
                    name: `${account.primaryContact.firstName} ${account.primaryContact.lastName}`,
                    mobileNumber: account.primaryContact.mobileNumber,
                    mobileNumberCode: account.primaryContact.mobileNumberCode,
                    email: account.primaryContact.email,
                    role: account.primaryContact.role,
                  }}
                />
              )}
              {activeStatus === StatusFE.Suspend &&
                account.status !== StatusBE.Suspend && (
                  <ModalAction
                    buttonText="suspend"
                    isChecked={isActionConfirmed}
                    color={backgroundColor}
                    submitAction={onSuspend}
                    closeModal={closeWindow}
                    toggleStatus={toggleSuspendStatus}
                    actionTypeText="YES SUSPEND THIS ACCOUNT"
                    note="All offers will expire until reactivated"
                  />
                )}
              {activeStatus === StatusFE.Delete && (
                <ModalAction
                  buttonText="delete"
                  isChecked={isActionConfirmed}
                  color={backgroundColor}
                  submitAction={onDelete}
                  closeModal={closeWindow}
                  toggleStatus={toggleDeleteStatus}
                  actionTypeText="YES DELETE THIS ACCOUNT"
                  note="Remove all assigned offers & assets"
                />
              )}
            </>
          )}
        </DetailsModalContent>
      </Sticky>
    </DetailsModalContainer>
  );
};

export default CorporateDetailsModal;
